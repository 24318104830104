import React, { Component } from "react";
//import "./AdwithPage.scss";
import Header from "./Header";

class PrivacyPage extends Component {
  render() {
    return (
      <>
      <div style ={{
          top: '1000',
      }}>

        <p>
        Thank you for using Scheduler. This Privacy Policy explains our practices regarding the collection, use, and disclosure of information we collect from users of our Services, including our bot and website. This Privacy Policy does not apply to any third-party websites, services, or applications, even if they are accessible through our Services. This Privacy Policy applies to all products and services offered by Scheduler. By accessing or using our Services, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.

Information Collection and Use
Our primary goals in collecting information are to provide and improve our Services, to administer your use of our Services, and to enable you to enjoy and easily navigate our Services.

Information Collected Through Our Site
If you visit our Site, you may choose to provide us with your email address or other contact information in order to obtain information about our Services. We collect this information only for our marketing purposes and will not share this information with third parties.

Account Information
If you create an Account, we’ll collect certain information that can be used to identify you, such as your name, email address, postal address and phone number (“PII”). If you create an Account using your login credentials from a third-party single sign-on service (SSO Service), we’ll be able to access and collect your name, email address, and other PII that your privacy settings on the third-party service permit us to access. If you log into your account with a third-party SSO Service we will receive a login validation token, but will not receive or store your single sign-on service password. We may also collect your gender, date of birth, and other information that is not considered PII because it cannot be used by itself to identify you.

Information Collected Using Cookies and other Web Technologies
Like many website owners and operators, we use automated data collection tools such as Cookies and Web Beacons to collect certain information.

“Cookies” are small text files that are placed on your hard drive by a Web server when you access our Services. We may use both session Cookies and persistent Cookies to identify that you’ve logged in to the Services and to tell us how and when you interact with our Services. We may also use Cookies to monitor aggregate usage and web traffic routing on our Services and to customize and improve our Services. Unlike persistent Cookies, session Cookies are deleted when you log off from the Services and close your browser. Although most browsers automatically accept Cookies, you can change your browser options to stop automatically accepting Cookies or to prompt you before accepting Cookies. Please note, however, that if you don’t accept Cookies, you may not be able to access all portions or features of the Services. Some third-party services providers that we engage (including third-party advertisers) may also place their own Cookies on your hard drive. Note that this Privacy Policy covers only our use of Cookies and does not include use of Cookies by such third parties.
“Web Beacons” (also known as Web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with Cookies, to track and measure the performance of our Services, to monitor how many visitors view our Services, and to monitor the effectiveness of our advertising. Unlike Cookies, which are stored on the user’s hard drive, Web Beacons are typically embedded invisibly on web pages (or in an email).
“APIs” or application programming interfaces are software components that enable our Services to communicate with third-party services. We may use APIs to enable our Services to communicate with and gather information from third-party services, such as SSO Services, used by you.
Our Site does not have the capability to respond to “Do Not Track” signals received from various web browsers.
Information Related to Use of Our Services
As part of you and your employees’ use of our Services, we will collect and analyze data provided to us via the various third-party accounts you enable for use with our Services. Because the third party accounts are enterprise business applications that you and your employees use in your day-to-day operations and communications, we may receive trade secrets or other confidential or proprietary information about your business, employees, or other highly sensitive topics that may be incidental to our efforts to collect information about organizational structure and communication as part of our Services.

Our servers automatically record certain information about how you our Services (we refer to this information as “Log Data”), including both Account holders and non-Account holders. Log Data may include information such as your Internet Protocol (IP) address, browser type, operating system, the web page that you were visiting before accessing our Services, the pages or features of our Services to which you browsed and the time spent on those pages or features, search terms, the links on our Services that you clicked on, comments and other communications conducted over our Services, information from other third party products and services that we enable you to connect to you Accounts and other statistics.

We use Log Data to administer the Services and we analyze (and may engage third parties to analyze) Log Data to improve, customize and enhance our Services by expanding their features and functionality and tailoring them to your needs and preferences. We may use your IP address to generate aggregate, non-identifying information about how our Services are used.

Location Information
When you use our services we may collect and store information about your location, such as by converting your IP address into a rough geolocation, or, if you access our Services via a mobile device, by accessing your mobile device’s coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect location information, you may disable that feature on your mobile device. In addition, some of the third-party accounts you use with our Services may contain your location information, which may then be shared with us.

Information Use
Any of the information we collect from you may be used to: * Personalize your experience (your information helps us to better respond to your individual needs) * Improve our Services (we may use your information and feedback to improve our Services, including our products, bots, and website) * Improve customer service (your information helps us to more effectively respond to your customer service requests and support needs) * Process payments * Run promotions, contests, surveys, or other features * Market products and services to you that we believe may be of interest to you * Send you information you agreed to receive about topics we think will be of interest to you * Send periodic emails with updates pertaining to our Services * Respond to your inquiries, questions, and/or other requests

We may also use your Personal Information to create Anonymous Information records by excluding information that makes the information personally identifiable to you. We reserve the right to use Anonymous Information for any purpose.

Information We Share
We will not share any PII that we have collected from or regarding you except as described below:

Information Shared with Our Services Providers. We may engage third party services providers to work with us to administer and provide our Services. These third party services providers have access to your PII only for the purpose of performing services on our behalf.
Information Shared with Third Parties. We may share aggregated information and non-identifying information with third parties to enhance the effectiveness of our Services and for industry research and analysis, demographic profiling, benchmarking, and other similar purposes. As part of our Services we may share aggregate, anonymous demographic information, survey results, market trends, and other analysis that we create based on the information we receive from you and other customers. Such information will never identify you, your business, or your employees.
Information Disclosed in Connection with Business Transactions. Information that we collect from our users, including PII, is considered to be a business asset. Thus, if we are acquired by a third party as a result of a transaction such as a merger, acquisition, or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third party acquirer in connection with the transaction.
Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.
Data Protection
We take reasonable measures to protect the information that we collect from you or about you (including your PII) from unauthorized access, use, or disclosure. Please be aware, however, that no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute security of any information.

Modifying Your Information
We’ll take steps to delete your information as soon as is practicable, but some information may remain in archived/backup copies for our records or as otherwise required by law.

International Transfer
Your PII may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you’re located outside the United States and choose to provide your PII to us, we may transfer your PII to the United States and process it there.

Third Party Websites and Services
Our Services, including our bot and website, may contain links to websites and services that are owned and operated by third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites and services linked to or from our Services. Any information that you provide on or to a third party website or service or that is collected by a third party website or service is provided directly to its owner or operator and is subject to the owner’s or operator’s privacy policy. To protect your information we recommend that you carefully review the privacy policies of any website or service that you access.

Children’s Privacy
Our Services are not directed to children under 13, and we do not knowingly collect PII from children under 13. If we learn that we have collected PII of a child under 13, we will take steps to delete such information from our files as soon as possible.

Privacy Shield
Scheduler Technologies Inc. complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States. Scheduler Technologies Inc. has certified to the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit https://www.privacyshield.gov/.

Under the Privacy Shield Framework, we are responsible for the processing of personal information we receive and subsequently transfer to a third party acting as an agent on our behalf, such as a hosting services provider. We comply with the Privacy Shield Principles for all onward transfers of personal information from the EU or Switzerland, including the onward transfer liability provisions.

With respect to personal information received or transferred pursuant to the Privacy Shield Framework, we are subject to the regulatory enforcement powers of the U.S. Federal Trade Commission.

In compliance with the Privacy Shield Principles, Scheduler Technologies Inc. commits to resolve complaints about our collection or use of your personal information. EU and Swiss individuals with inquiries or complaints regarding our Privacy Shield policy should first contact Scheduler Technologies Inc.

EU and Swiss individuals whose personal information is covered by this Privacy Shield Policy have the right to access the personal information that Scheduler Technologies Inc. maintains about them to correct, amend or delete such personal information if it is inaccurate or has been processed in violation of the Principles (except when the burden or expense of providing access, correction, amendment, or deletion would be disproportionate to the risks to the Data Subject’s privacy, or where the rights of persons other than the Data Subject would be violated). Requests for access, correction, amendment or deletion should be sent to: privacy@Scheduler.ai

If Personal Data covered by this Privacy Shield Policy is to be used for a new purpose that is materially different from that for which the Personal Data was originally collected or subsequently authorized, or is to be disclosed to a non-agent third party, Scheduler Technologies Inc. will provide Data Subjects with an opportunity to choose whether to have their Personal Data so used or disclosed.

Scheduler Technologies Inc. has further committed to refer unresolved Privacy Shield complaints to JAMS, an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we have not addressed your complaint to your satisfaction, please contact or visit https://www.jamsadr.com/eu-us-privacy-shield for more information or to file a complaint. The services of JAMS are provided at no cost to you.

Under certain conditions, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted. For more information, please visit the Privacy Shield website.

Revisions to this Privacy Policy
We reserve the right to revise this Privacy Policy from time to time. We will post changes on this page and indicate the “last modified” date at the top of this page. Please check back often for any updates. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically, and that your continued use of our Services after any change in this Privacy Policy will constitute your acceptance of such change.

Your Acceptance of These Terms
By using our Services, you signify your acceptance of this Privacy Policy.
        </p>
        </div>

      </>
    );
  }
}
export default PrivacyPage;
