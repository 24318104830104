import React, { useLayoutEffect, useRef, useState } from "react";
//import SvgLines from "react-mt-svg-lines"; // ES6+
import styled from "styled-components";

import Header from "./Header";
import "./JoshPage.scss";
import queen from "../images/queen.gif";
import line1 from "../images/line1.svg";
import waterpolo from "../images/waterpolo.png";
import ycba from "../images/ycba.jpg";
import ironman from "../images/ironman.png";
import josh from "../images/josh.png";
import shh from "../images/shh.png";
import logo from "../images/logo.png";
import helix from "../images/vna.gif";
import ron from "../images/ron.gif";
import linkedin from "../images/linkedin.png";
import medium from "../images/medium.png";
import email from "../images/email.png";

//import AOS from "aos";
//import "aos/dist/aos.css";

//import ScrollAnimation from "react-animate-on-scroll";
const Div = styled.div`
  opacity: ${({ animate }) => (animate ? "1" : "0")};
  transform: translateY(${({ animate }) => (animate ? "0px" : "50px")});
  transition: opacity 2s, transform 1s;
`;

const Svg = styled.svg`
  stroke-dashoffset: ${({ animate }) => (animate ? "0" : "2000")};
`;

//opacity: ${({animate}) => animate ? "1" : "0"};
//transition: opacity 5s;

//stroke-dashoffset: ${({animate}) => animate ? "1" : "1000"};
//  transition: stroke-dashoffset 2s;

/*position: absolute;
width: 151px;
height: 300px;
left: 237px;
top: 250px;
stroke-dashoffset: ${({animate}) => animate ? "1" : "1000"};
transition: stroke-dashoffset 2s; 
*/

const styles = {
  height: "900px",
  width: "100%",
  padding: "10px",

  margin: "20px",
  strokeDashoffset: "15%",
};

const Wrapper = styled.div`
  margin-top: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

//
//opacity : ${({animate}) => (animate ? 1 : 0)};
//transform: translateX(${({ animate }) => (animate ? "0" : "-100vw")});
//transition: opacity 2s;
const JoshPage = () => {
  const [show, doShow] = useState({
    panelOne: false,
    panelTwo: false,
    panelThree: false,
    panelFour: false,
    panelFive: false,
    panelSix: false,
    panelSeven: false,
    panelEight: false,
    panelNine: false,
    panelTen: false,
  });

  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);
  const refSeven = useRef(null);
  const refEight = useRef(null);
  const refNine = useRef(null);
  const refTen = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;

    const div1Pos = topPos(refOne.current);
    const div2Pos = topPos(refTwo.current);
    const div3Pos = topPos(refThree.current);
    const div4Pos = topPos(refFour.current);
    const div5Pos = topPos(refFive.current);
    const div6Pos = topPos(refSix.current);
    const div7Pos = topPos(refSeven.current);
    const div8Pos = topPos(refEight.current);
    const div9Pos = topPos(refNine.current);
    const div10Pos = topPos(refTen.current);

    const onScroll = () => {
      let scrollPos = window.scrollY + window.innerHeight;

      scrollPos = 0.55 * scrollPos;

      if (div1Pos - 850 < scrollPos) {
        doShow((state) => ({ ...state, panelOne: true }));
        console.log(1, div1Pos);
      }
      if (div2Pos - 850 < scrollPos) {
        doShow((state) => ({ ...state, panelTwo: true }));
        console.log(2, div2Pos);
      }
      if (div3Pos + 50 < scrollPos) {
        doShow((state) => ({ ...state, panelThree: true }));
        console.log(3, div3Pos);
      }
      if (div4Pos - 130 < scrollPos) {
        doShow((state) => ({ ...state, panelFour: true }));
        console.log(4, div4Pos);
      }
      if (div5Pos - 50 < scrollPos) {
        doShow((state) => ({ ...state, panelFive: true }));
        console.log(5, div5Pos);
      }
      if (div6Pos - 300 < scrollPos) {
        doShow((state) => ({ ...state, panelSix: true }));
        console.log(6, div6Pos);
      }
      if (div7Pos - 150 < scrollPos) {
        doShow((state) => ({ ...state, panelSeven: true }));
        console.log(7, div7Pos);
      }
      if (div8Pos - 425 < scrollPos) {
        doShow((state) => ({ ...state, panelEight: true }));
        console.log(8, div8Pos);
      }
      if (div9Pos - 300 < scrollPos) {
        doShow((state) => ({ ...state, panelNine: true }));
        console.log(9, div9Pos);
      }
      if (div10Pos - 850 < scrollPos) {
        doShow((state) => ({ ...state, panelTen: true }));
        console.log(10, div10Pos);
      }
      console.log("----");
      console.log(scrollPos);
      console.log("-----");
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {/* <div className="cool-box" /> */}
      <div>
        <p className="joshtext">
          Hey 👋 I’m Josh<p className="point">👇</p>
        </p>
        <Svg
          className="line1"
          animate={show.panelOne}
          ref={refOne}
          width="158"
          height="336"
          viewBox="0 0 158 336"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M64.5975 7C17.2782 34.4969 -46.384 102.561 64.5975 160.501C68.0082 162.281 71.5839 164.052 75.3301 165.812C200.973 224.842 148.431 299.866 106.455 330"
            stroke="#6693AB"
            strokeWidth="6"
          />
        </Svg>

        <p className="england">
          <Div ref={refTwo} animate={show.panelTwo}>
            I was born in <b>England</b>, but grew up on both coasts and now
            live in <b>Austin</b>
          </Div>
        </p>
      </div>

      {/*<img class="endellipse2" src={orange}></img>

    <img class="endellipse" src={orange}></img>*/}

      <Svg
        className="line2"
        animate={show.panelThree}
        ref={refThree}
        width="622"
        height="290"
        viewBox="0 0 622 290"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.03442 3.63458C677.517 82.7068 -197.259 83.6388 620.5 286.5"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      {/* <Svg
        className="line3"
        animate={show.panelFive}
        ref={refFive}
        width="880"
        height="450"
        viewBox="0 0 880 252"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M876.5 1.5C830 256.5 85.5 69 3 250.5"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg> */}

      <Svg
        className="line3a"
        animate={show.panelFive}
        ref={refFive}
        width="1107"
        height="394"
        viewBox="0 0 1107 394"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1104 1C1045.39 401.422 106.987 106.994 3 392"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        className="line4"
        animate={show.panelSeven}
        ref={refSeven}
        width="730"
        height="289"
        viewBox="0 0 730 289"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.41135 1C-55.822 221.769 754.409 71.5384 726.284 288"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        animate={show.panelNine}
        ref={refNine}
        className="line5"
        width="170"
        height="733"
        viewBox="0 0 170 733"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.2177 2.42236C-20.4229 62.7 18.191 105.615 137.33 135.184C256.469 164.754 -34.8717 408.552 41.2177 234.152C117.307 59.7512 137.33 726.577 162.86 408.552C188.39 90.5279 90.2751 602.264 21.1943 547.952C-47.8866 493.641 106.726 476.072 41.2178 731.94"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        animate={show.panelTen}
        className="line8"
        width="393"
        height="192"
        viewBox="0 0 393 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 2.95679C332.2 73.7878 -54.5 70.8189 117 125.957C288.5 181.095 397 180.034 389 189.577"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        animate={show.panelTen}
        className="line9"
        width="341"
        height="303"
        viewBox="0 0 341 303"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M337.636 1.44043C304.207 67.6892 12.9007 14.3216 103.636 136.698C194.37 259.074 -106.965 253.093 48.2395 299.559"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        animate={show.panelTen}
        className="line10"
        width="118"
        height="160"
        viewBox="0 0 118 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 156.5C65.5 125 68.5 8.5 117 3.00049"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Svg
        animate={show.panelTen}
        className="line11"
        width="250"
        height="312"
        viewBox="0 0 250 312"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6551 3C-23.3922 39.7123 22.6547 400.451 47.2848 203.588C71.9149 6.72445 247 310 247 310"
          stroke="#6693AB"
          stroke-width="6"
        />
      </Svg>

      <Div animate={show.panelTwo}>
        <img className="queen" src={queen}></img>
        <p className="queencaption">
          The queen almost ran over me when I was two
        </p>
      </Div>

      <p className="yale">
        <Div animate={show.panelFour} ref={refFour}>
          I do <b>eng + prod</b> and am a guide and curator at the Yale Center
          for British Art
        </Div>
      </p>

      <Div animate={show.panelFour}>
        <img className="ycba" src={ycba}></img>
      </Div>

      <p className="wapo">
        <Div animate={show.panelSix} ref={refSix}>
          I’m also the captain of <b>Yale Water Polo</b>
        </Div>
      </p>
      <Div animate={show.panelSix}>
        <img className="wapoPic" src={waterpolo}></img>
      </Div>
      <p className="ironman">
        <Div animate={show.panelEight} ref={refEight}>
          And was the youngest finisher @ <b>Ironman Santa Rosa</b> in 2019
        </Div>
      </p>
      <Div animate={show.panelEight}>
        <img className="ironmanPic" src={ironman}></img>
      </Div>

      <p className="but">
        <Div animate={show.panelTen} ref={refTen}>
          But, above all else...
        </Div>
      </p>
      <p className="founder">
        <Div animate={show.panelTen}>I'm a Founder</Div>
      </p>
      <p className="past">
        <Div animate={show.panelTen}>Past</Div>
      </p>
      <p className="future">
        <Div animate={show.panelTen}>Future</Div>
      </p>
      <p className="kiki">
        <Div animate={show.panelTen}>
          Founder + CTO @ <b>Kiki Air</b> – the drone-powered college
          convenience store
        </Div>
      </p>
      <p className="ondeck">
        <Div animate={show.panelTen}>
          Dreaming up ideas and meeting cool people @ <b>On Deck</b> +{" "}
          <b>Soma Capital</b>
        </Div>
      </p>
      <p className="new">
        <Div animate={show.panelTen}>PS: Building something new... 🥑</Div>
      </p>
      <p className="helix">
        <Div animate={show.panelTen}>
          Director + Engineer @ the <b>Helix Incubator</b>
        </Div>
      </p>

      <p className="shh">
        <Div animate={show.panelTen}>🤫</Div>
      </p>

      <Div animate={show.panelTen}>
        <img className="kikiLogo" src={logo}></img>
      </Div>

      <Div animate={show.panelTen}>
        <img className="helixLogo" src={helix}></img>
      </Div>

      <Div animate={show.panelTen}>
        <img className="ron" src={ron}></img>
      </Div>
      <Div animate={show.panelTen}>
        <img className="me" src={josh}></img>
      </Div>
      <Div animate={show.panelTen}>
        <a
          href="https://www.linkedin.com/in/josh-ip-50a011178/"
          target="_blank"
        >
          <img className="linkedin" src={linkedin}></img>
        </a>
      </Div>
      <Div animate={show.panelTen}>
        <a href="mailto:joshua.ip@yale.edu" target="_blank">
          <img className="email" src={email}></img>
        </a>
      </Div>
      <Div animate={show.panelTen}>
        <a href="https://medium.com/@josh_ip" target="_blank">
          <img className="medium" src={medium}></img>
        </a>
      </Div>
    </>
  );
};

export default JoshPage;
