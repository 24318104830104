import React, { Component } from "react";
import "./AdwithPage.scss";

class Logo extends Component {
  render() {
    return (
      <div className="useless-labs">
        <div className="text">u</div>
      </div>
    );
  }
}

export default Logo; 
