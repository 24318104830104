import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Header from "./Header";
import JoshPage from "./JoshPage"
import LandingPage from "./LandingPage"
import PrivacyPage from "./privacypolicy";

const Landing = () => <h2> Landing </h2>;

const App = () => {
  console.log("does this work")
  return (
    <div>
     { <Header/> }
      <BrowserRouter>
        <div>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/josh" component={JoshPage} />
          <Route path="/adwith" component={LandingPage} />
          <Route path="/privacy" component={PrivacyPage} />

        </div>

      </BrowserRouter>

    </div>
  );
};

export default App;