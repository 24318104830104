import React, { Component } from "react";
import "./AdwithPage.scss";
import Header from "./Header";

class LandingPage extends Component {
  render() {
    return (
      <>
        <div className="cool-box" />

        <Header />

        <div className="projects">
          <span className="project">
            {" "}
            coming 
            <div className="detail" />
          </span>

          <span className="project">
            {" "}
            soon
            <div className="detail" />
          </span>
          { /*
          <span className="project">
            {" "}
            python
            <div className="detail" />
          </span>
          <span className="project">
            {" "}
            swift
            <div className="detail" />
          </span>
          <span className="project">
            {" "}
            unity
            <div className="detail" />
          </span>
          <span className="project">
            {" "}
            html
            <div className="detail" />
          </span>
          <span className="project">
            {" "}
            design
            <div className="detail" />
          </span>*/}
          </div> 

        <div className="social"></div>
      </>
    );
  }
}
export default LandingPage;
