import React, { Component } from "react";
import "./AdwithPage.scss";

class Menu extends Component {
  render() {
    return (
      <div className="menu-title">
        
        
        <div className="menu-text">menu </div>
        
        <a href = "/">
            <div className="menu-item">projects</div>
        </a>
        <a href = "/adwith">
            <div className="menu-item"><b>/</b>adwith</div>
        </a>
        <a href = "/josh">
            <div className="menu-item"><b>/</b>josh</div>
        </a>
      </div>
    );
  }
}

export default Menu;
